<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    :dark="app.isDark"
    :style="styles"
    floating
    mobile-breakpoint="991"
    persistent
    width="260"
    color="primary"
  >
    <!-- <template v-slot:img="attrs">
      <v-img
        v-bind="attrs"
        gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)"
      />
    </template> -->

    <v-list-item
      two-line
      class="my-3"
    >
      <v-list-item-title class="title">
        <v-img
          class=""
          src="@/assets/logo-bouygues-telecom-entreprises-white.png"
          height="80px"
          contain
        />
        <v-img
          class="mt-5"
          src="@/assets/powered_by_himydata.png"
          height="24px"
          position="center"
          contain
        />
      </v-list-item-title>
    </v-list-item>

    <v-divider class="mt-8 mx-3 ma-4"/>

    <v-list nav>
      <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
      <div/>

      <v-list-item
        v-for="(link, i) in filteredLinks"
        :key="i"
        :to="link.to"
        active-class="white--text"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>

        <v-list-item-title v-text="link.text"/>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list nav>
        <v-list-item v-if="isDebug">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>

          <v-list-item-title class="font-weight-light">
            <v-select v-model="selectedGroup" :items="groups" @change="changeGroup"/>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>

          <v-list-item-title class="font-weight-light">
            Déconnexion
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import DrawerLinks from '@/utils/drawer-links'
  import { logout } from '@/utils/auth'
  import Constant from "@/constant"

  import { mapMutations, mapState } from 'vuex'

  const Config = require('@/utils/theme-config')
  export default {
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      app: {
        logo: Config.app.logo,
        isDark: Config.drawer.isDark,
      },
      isDebug: Constant.DEBUG,
      groups: [...Constant.groups()],
      selectedGroup: null,
      links: [],
    }),
    computed: {
      styles () {
        const temp = {}
        return {
          background:
            Config.drawer.bgColor[
              this.$store.getters.isUserAdmin ? 'admin' : 'default'
              ],
        }
      },
      ...mapState('app', ['image', 'color']),
      inputValue: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      filteredLinks(){
        return this.links.filter(l => !l.hide)
      }
    },
    methods: {
      ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
      handleLogout () {
        logout()
      },
      changeGroup(){
        this.$store.commit('setGroup', this.selectedGroup)
        this.links = DrawerLinks.generateLink(this.selectedGroup)
      }
    },
    mounted(){
      this.selectedGroup = this.$store.getters.group
      this.links = DrawerLinks.generateLink(this.selectedGroup)
    }
  }
</script>


<style lang="scss" scoped>


</style>

